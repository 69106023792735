/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require('react')
const { ContextProvider } = require('./src/context/index')
const browserUpdate = require('browser-update')

browserUpdate({
  required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
  insecure: true,
  unsupported: true,
  mobile: false,
  api: 2020.05
})

exports.wrapRootElement = ({ element }) => <ContextProvider>{element}</ContextProvider>
