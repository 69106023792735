/* Packages */
import React, { useContext } from 'react'
/* Imported Components */
import { Context } from '../../../../context'
import MenuItem from './menuItem/menuItem'
/* Styles */
import styles from './menu.module.scss'
/* Component */
const Menu = () => {
  const { menuItems } = useContext(Context)

  return (
    <ul className={styles.menu}>
      {menuItems.filter(item => item.visibleInMenu).map((item, i) => (
        <MenuItem key={i} item={item} id={i} />
      ))}
    </ul>
  )
}
/* Exports */
export default Menu
