/* Packages */
import React from 'react'
/* Imported Components */
import LeftFooter from './leftFooter/leftFooter'
import RightFooter from './rightFooter/rightFooter'
/* Styles */
import styles from './footer.module.scss'
/* Component */
const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <LeftFooter />
      <RightFooter />
    </div>
  </footer>
)
/* Exports */
export default Footer
