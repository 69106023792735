/* Packages */
import React, { useContext } from 'react'
import { Link } from 'gatsby'
/* Imported Components */
import { Context } from '../../../../../context'
/* Styles */
import styles from './menuItem.module.scss'
/* Component */
const MenuItem = ({ item: { link, title }, toggleMenu }) => {
  const { setClearSelections } = useContext(Context)
  const cleanup = () => {
    setClearSelections(true)
    toggleMenu()
  }

  return (
    <Link className={styles.link} activeClassName={styles.active} to={link} onClick={cleanup}>
      <li className={styles.item}>
        <strong>{title}</strong>
      </li>
    </Link>
  )
}
/* Exports */
export default MenuItem
