/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
/* Imported Components */
import { Context } from '../../../../../context'
/* Styles */
import styles from './menuItem.module.scss'
/* Component */
const MenuItem = ({ item: { link, title }, id }) => {
  const { setClearSelections } = useContext(Context)
  const [menuItemClasses, setMenuItemClasses] = useState(styles.link + ' ' + styles.hide)

  const cleanup = () => {
    setClearSelections(true)
  }

  useEffect(() => {
    setTimeout(() => {
      setMenuItemClasses(styles.link)
    }, (150 * (id + 1)) + 2500)
  }, [title])

  return (
    <Link id={'menu-item-' + id} className={menuItemClasses} activeClassName={styles.active} to={link} partiallyActive={link !== '/'} onClick={cleanup}>
      <li className={styles.item}>
        <strong>{title}</strong>
      </li>
    </Link>
  )
}
/* Exports */
export default MenuItem
