/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
/* Imported Components */
import { Context } from '../../../../context'
/* Styles */
import styles from './logo.module.scss'
/* Component */
const Logo = () => {
  const { mobileLogo, siteLogo } = useContext(Context)
  const [logoClasses, setLogoClasses] = useState(styles.logo)
  const [mobileLogoClasses, setMobileLogoClasses] = useState(styles.mobileLogo)

  useEffect(() => {
    setTimeout(() => {
      setLogoClasses(styles.logo + ' ' + styles.show)
      setMobileLogoClasses(styles.mobileLogo + ' ' + styles.show)
    }, 2500)
  }, [siteLogo, mobileLogo])

  return (
    siteLogo && mobileLogo && (
      <div>
        <Link className={styles.link} to='/'>
          <img className={logoClasses} src={siteLogo} alt='T.D. Williamson 100 years' />
          <img className={mobileLogoClasses} src={mobileLogo} alt='T.D. Williamson 100 years' />
        </Link>
      </div>
    )
  )
}
/* Exports */
export default Logo
