/* Packages */
import React, { createContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
/* Imported Components */
import Header from '../components/layout/header/header'
import Footer from '../components/layout/footer/footer'
/* Styles */
import styles from './index.module.scss'
/* Other */
const Context = createContext()
/* Component */
const ContextProvider = ({ children }) => {
  const [{ allContentfulSettings }] = useState(useStaticQuery(graphql`
  {
    allContentfulSettings {
      nodes {
        siteTitle
        siteAuthor
        siteDescription
        homeFeaturedText
        footerTextLeft
        footerTextRight
        footerLinks {
          text
          link
          openInNewWindow
          displayInMobileMenu
        }
        menuArrow {
          file {
            url
          }
        }
        video {
          file {
            url
          }
        }
        arrowLeft {
          file {
            url
          }
        }
        arrowRight {
          file {
            url
          }
        }
        logo {
          file {
            url
          }
        }
        mobileLogo {
          file {
            url
          }
        }
        homeFeaturedLogo {
          file {
            url
          }
        }
        homePageLogoState
        text100for100events {
          text100for100events
        }
        menuItems {
          title
          link
          visibleInMenu
        }
        homeImages {
          title
          text
          image {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          mobileImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
        archives {
          title
          mediaType
          image {
            title
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          items {
            ... on ContentfulArchiveImage {
              title
              showTitle
              image {
                title
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
                file {
                  url
                }
              }
            }
            ... on ContentfulArchiveVideo {
              title
              showTitle
              url
            }
            ... on ContentfulArchiveDocument {
              title
              showTitle
              document {
                file {
                  url
                }
              }
              thumbnail {
                title
                file {
                  url
                }
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
        events {
          title
          body {
            body
          }
          footer {
            footer
          }
          images {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
        futureSections {
          order
          title
          sectionDisplay
          divider
          image {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          text {
            text
          }
          redText {
            redText
          }
          videoUrl
        }
        historyTimelineItems {
          title
          image {
            title
            description
            file {
              url
            }
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          milestones {
            title
            order
            category
            itemDisplay
            images {
              title
              description
              file {
                url
              }
            }
            headlineLeft
            headlineRight
            textLeft {
              textLeft
            }
            textRight {
              textRight
            }
          }
        }
      }
    }
  }
  
  
  `))

  const [windowSize, setWindowSize] = useState({ height: 0, width: 0, device: 'computer' })
  const [layoutClasses, setLayoutClasses] = useState(styles.layout)
  const [settings, setSettings] = useState()
  const [site, setSite] = useState({ title: '', author: '', description: '' })
  const [siteLogo, setSiteLogo] = useState('')
  const [mobileLogo, setMobileLogo] = useState('')
  const [menuArrow, setMenuArrow] = useState('')
  const [arrowLeft, setArrowLeft] = useState('')
  const [arrowRight, setArrowRight] = useState('')
  const [menuItems, setMenuItems] = useState([])
  const [homeFeaturedLogo, setHomeFeaturedLogo] = useState('')
  const [homePageLogoState, setHomePageLogoState] = useState(false)
  const [homeFeaturedText, setHomeFeaturedText] = useState('')
  const [homeImages, setHomeImages] = useState([])
  const [events, setEvents] = useState([])
  const [eventsText, setEventsText] = useState('')
  const [futureSections, setFutureSections] = useState([])
  const [footerLeft, setFooterLeft] = useState('')
  const [footerRight, setFooterRight] = useState('')
  const [footerLinks, setFooterLinks] = useState([])
  const [homeVideo, setHomeVideo] = useState('')
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [archives, setArchives] = useState([])
  const [history, setHistory] = useState([])
  const [clearSelections, setClearSelections] = useState(false)

  useEffect(() => allContentfulSettings && setSettings(allContentfulSettings.nodes[0]))

  useEffect(() => {
    if (settings) {
      setSite({ title: settings.siteTitle, author: settings.siteAuthor, description: settings.siteDescription })
      setMenuItems(settings.menuItems)
      setEvents(settings.events)
      setFutureSections(settings.futureSections)
      setSiteLogo(settings.logo.file.url)
      setMenuArrow(settings.menuArrow.file.url)
      setArrowLeft(settings.arrowLeft.file.url)
      setArrowRight(settings.arrowRight.file.url)
      setMobileLogo(settings.mobileLogo.file.url)
      setHomeFeaturedLogo(settings.homeFeaturedLogo.file.url)
      setHomePageLogoState(settings.homePageLogoState)
      setFooterLeft(settings.footerTextLeft)
      setFooterRight(settings.footerTextRight)
      setFooterLinks(settings.footerLinks)
      setHomeFeaturedText(settings.homeFeaturedText)
      setHomeVideo(settings.video.file.url)
      setArchives(settings.archives)
      setHomeImages(settings.homeImages)
      setEventsText(settings.text100for100events.text100for100events)
      setHistory(settings.historyTimelineItems)
    }
  }, [settings])

  // Runs once per site load
  useEffect(() => {
    const sizeListener = () => {
      // Set the height of a vh unit
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
      // Update the windowSize context object
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        device: window.innerWidth >= 1337 ? 'computer' : window.innerWidth >= 768 ? 'tablet' : 'phone'
      })
    }

    sizeListener()
    window.addEventListener('resize', sizeListener)
  }, [])

  useEffect(() => {
    windowSize.device === 'computer' && setLayoutClasses(styles.layout)
    windowSize.device === 'tablet' && setLayoutClasses(styles.layout + ' ' + styles.tablet)
    windowSize.device === 'phone' && setLayoutClasses(styles.layout + ' ' + styles.phone)
  }, [windowSize])

  useEffect(() => {
    const lastPlayed = window.localStorage.getItem('intro-last-played')
    if (lastPlayed === moment().format('DDMMYYYY')) {
      setVideoPlayed(true)
    } else {
      window.localStorage.setItem('intro-last-played', moment().format('DDMMYYYY'))
    }
  }, [])

  return (
    <Context.Provider value={{ footerLeft, footerRight, menuItems, site, menuArrow, arrowLeft, arrowRight, siteLogo, mobileLogo, homeFeaturedLogo, homeFeaturedText, homeImages, homePageLogoState, futureSections, eventsText, events, windowSize, archives, history, clearSelections, setClearSelections, footerLinks }}>
      {videoPlayed && (
        <div className={layoutClasses}>
          <Header />{children}<Footer />
        </div>
      )}
      {!videoPlayed && (
        <div style={{ height: '100vh', width: '100vw', position: 'fixed', top: '0', left: '0', overflow: 'hidden' }}>
          {windowSize.device === 'phone' ? setVideoPlayed(true) : <video src={homeVideo} preload='auto' muted autoPlay onClick={() => setVideoPlayed(true)} onEnded={() => setVideoPlayed(true)} style={{ height: '100%', width: '177.77777778vh', minWidth: '100%', minHeight: '56.25vw', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />}
        </div>
      )}
    </Context.Provider>)
}
/* Exports */
export { ContextProvider, Context }
