/* Packages */
import React, { useContext, useEffect } from 'react'
/* Imported Components */
import { Context } from '../../../../context'
/* Styles */
import styles from './rightFooter.module.scss'
/* Component */
const RightFooter = () => {
  const { footerRight, footerLinks } = useContext(Context)

  useEffect(() => {
    if (footerRight) {
      setTimeout(() => {
        document.getElementsByClassName(styles.rightFooter)[0].classList.add(styles.show)
      }, 3500)
    }
  }, [footerRight])

  let i = 1
  return (
    <div className={styles.rightFooter}>
      {footerLinks.map((link) => <a className={styles.text} target={link.openInNewWindow ? '_blank' : '_self'} rel='noopener noreferrer' style={{ gridColumn: i }} key={i++} href={link.link}>{link.text}</a>)}
      <p style={{ gridColumn: i++ }} className={styles.text}>
        {footerRight}
      </p>
      <a style={{ gridColumn: i, color: '#ccc' }} className={styles.text} href='https://station8branding.com' target='_blank' rel='noopener noreferrer'>designed & developed by Station8</a>
    </div>
  )
}
/* Exports */
export default RightFooter
