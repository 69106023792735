// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archives-jsx": () => import("./../src/pages/archives.jsx" /* webpackChunkName: "component---src-pages-archives-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-events-jsx": () => import("./../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-future-jsx": () => import("./../src/pages/future.jsx" /* webpackChunkName: "component---src-pages-future-jsx" */),
  "component---src-pages-history-jsx": () => import("./../src/pages/history.jsx" /* webpackChunkName: "component---src-pages-history-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

