/* Packages */
import React, { useContext } from 'react'
/* Imported Components */
import { Context } from '../../../context'
import Logo from './logo/logo'
import Menu from './menu/menu'
import MobileMenu from './mobileMenu/mobileMenu'
import TabletMenu from './tabletMenu/tabletMenu'
/* Styles */
import styles from './header.module.scss'
/* Component */
const Header = () => {
  const { windowSize: { device } } = useContext(Context)

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Logo />
        {device === 'computer' ? <Menu /> : device === 'tablet' ? <TabletMenu /> : <MobileMenu />}
      </div>
    </header>
  )
}
/* Exports */
export default Header
