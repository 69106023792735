/* Packages */
import React, { useContext, useEffect } from 'react'
/* Imported Components */
import { Context } from '../../../../context'
/* Styles */
import styles from './leftFooter.module.scss'
/* Component */
const LeftFooter = () => {
  const { footerLeft } = useContext(Context)

  useEffect(() => {
    if (footerLeft) {
      setTimeout(() => {
        document.getElementsByClassName(styles.leftFooter)[0].classList.add(styles.show)
      }, 3500)
    }
  }, [footerLeft])

  return (
    <div className={styles.leftFooter}>
      <p className={styles.text}>
        <a className={styles.link} href='http://www.tdwilliamson.com'>
          <strong>{footerLeft}</strong>
        </a>
      </p>
    </div>
  )
}
/* Exports */
export default LeftFooter
