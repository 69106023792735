/* Packages */
import React, { useContext, useEffect } from 'react'
/* Imported Components */
import { Context } from '../../../../context'
import MenuItem from './menuItem/menuItem'
/* Styles */
import styles from './mobileMenu.module.scss'
/* Component */
const MobileMenu = () => {
  const { footerRight, footerLinks, menuItems, windowSize: { device } } = useContext(Context)

  const toggleMenu = () => {
    const menu = document.getElementById('mobile-menu')
    menu.style.display === 'grid' ? menu.style.display = 'none' : menu.style.display = 'grid'
  }

  useEffect(() => {
    if (menuItems && device && device !== 'computer') {
      setTimeout(() => {
        document.getElementsByClassName(styles.mobileMenuButton).length && document.getElementsByClassName(styles.mobileMenuButton)[0].classList.add(styles.show)
      }, 2500)
    }
  }, [menuItems, device])

  let i = 2

  return (
    <>
      <div className={styles.mobileMenuButton} onClick={toggleMenu}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
      <div id='mobile-menu' className={device !== 'phone' ? styles.mobileMenu : `${styles.mobileMenu} ${styles.phone}`}>
        <ul className={styles.menu}>
          {menuItems.filter(item => item.visibleInMenu).map((item, i) => (
            <MenuItem key={i} item={item} toggleMenu={toggleMenu} />
          ))}
        </ul>
        {device === 'phone' && (
          <div className={styles.footer}>
            <div className={styles.link} style={{ gridRow: '1' }}>
              <a href='http://www.tdwilliamson.com'>tdwilliamson.com</a>
            </div>
            {footerLinks.filter(link => link.displayInMobileMenu).map((link) => <a className={styles.text} target={link.openInNewWindow ? '_blank' : '_self'} rel='noopener noreferrer' style={{ gridRow: i }} key={i++} href={link.link}>{link.text}</a>)}
            <p style={{ gridRow: i++ }} className={styles.text}>
              {footerRight}
            </p>
            <a style={{ gridRow: i, color: '#ccc' }} className={styles.text} href='https://station8branding.com' target='_blank' rel='noopener noreferrer'>designed & developed by Station8</a>
          </div>
        )}
      </div>
    </>
  )
}
/* Exports */
export default MobileMenu
